import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image";

import Head from "../components/head"

import Layout from "../components/layout"

import blogPostsStyles from "../styles/modules/blogposts.module.scss"

export const query = graphql`
    query($slug: String!) {
        allWpPost(filter: { slug: { eq: $slug } }) {
          nodes {
            title
            slug
            content
            featuredImage {
                node {
                    localFile{
                        childImageSharp {
                            fluid(maxHeight: 1180) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                    }
                  sourceUrl
                  title
                }
              }
          }
        }
      }
    `

const Blog = ( props ) => {
    const { data } = props
    const post = data.allWpPost.nodes[0]

    return (
        <Layout css_class="post">
            <Head title={post.title} />
            <main>
                <div className="container">
                    <section>
                        <div className="row">
                            <div className="col">
                                <h1>{post.title}</h1>
                                <Img fluid={post.featuredImage.node.localFile.childImageSharp.fluid} key={post.featuredImage.node.localFile.childImageSharp.fluid.src} />
                                <div
                                    className="content"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            post.content,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </Layout>
    )
}

export default Blog
